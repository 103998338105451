<template>
    <b-modal
      id="modal-view-archivo"
      ref="my-modal-ver"
      cancel-variant="outline-secondary"
      cancel-title="Cerrar"
      ok-title="Actualizar"
      scrollable
      @ok.prevent="actualizarDatos"
      size="xl"
    >
        <b-row class="match-height">
          <b-col
              lg="7"
              md="7"
              sm="12"
          >
          <div id="iframe" class="boxsv boxsver1" v-if="selected">
              <b-img v-if="selected.tipo=='IMAGEN' || selected.tipo=='ESTADISTICA'" :src="selected.url" 
                center
                thumbnail
                fluid
                class="rounded"
                alt="Card image cap"
              />
              <b-embed v-else-if="selected.tipo=='DOCUMENTO'" 
                type="iframe" 
                aspect="16by9" 
                :src="selected.url" 
                allowfullscreen
                name="pdf-viewer"
              />
              <b-img v-else-if="selected.miniatura.length"
                  thumbnail
                  fluid
                  center
                  class="rounded"
                  :src="selected.miniatura" 
                  alt="Card image cap"
              />
              <b-img v-else
                  thumbnail
                  fluid
                  center
                  class="rounded"
                  :src='require(`@/assets/images/icons/file-icon/${selected.extencion}.png`)'
                  alt="Card image cap"
                />
          </div>
          </b-col>
          <b-col
              lg="5"
              md="5"
              sm="12"
          >
          <div>
            <validation-observer ref="simpleRules">
              <b-form>
                <b-row class="mb-1 my-1">

                  <b-col cols="12">
                      <b-form-group
                          label="Titulo"
                          label-for="Titulo"
                      >
                          <validation-provider
                            #default="{ errors }"
                            name="Titulo"
                            rules="required"
                            >
                            <b-form-input
                                id="titulo"
                                v-model="titulo"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Titulo"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            <small v-for="error in errores.titulo" :key="error" class="text-danger">{{ error }}</small>
                          </validation-provider>
                      </b-form-group>
                  </b-col>

                  <b-col cols="12">
                      <b-form-group
                          label="Categoria"
                          label-for="Categoria"
                      >
                          <v-select
                              label="title"
                              v-model="categoria"
                              :options="mediosCategoriaOpciones"
                              :clearable="false"
                          >
                          </v-select>
                      </b-form-group>
                  </b-col>
                  
                  <b-col lg="12">
                    <b-form-group
                      label="URL"
                      label-for="URL"
                    >
                      <b-input-group >
                        <b-form-input
                        disabled
                          v-model="selected.url"
                        />
                        <b-input-group-append>
                          <b-button
                          v-clipboard:copy="selected.url"
                          >
                            Copiar
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group
                      label="Tipo:"
                      label-for="Tipo:"
                    >
                      <b-card-text>
                        {{ selected.extencion }}
                      </b-card-text>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group
                      label="Tamaño:"
                      label-for="Tamaño:"
                    >
                      <b-card-text>
                        <span v-show="selected.peso">{{ selected.peso }} Kb.</span>
                      </b-card-text>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-form-group
                      label="Fecha Subida:"
                      label-for="Fecha Subida:"
                    >
                      <b-card-text>
                        {{ selected.created_at }}
                      </b-card-text>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-form-group
                    >
                       <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          size="sm"
                          @click="confirmar_borrar(selected.id)"
                        >
                          Borrar permanentemente
                        </b-button>
                    </b-form-group>
                  </b-col>

                </b-row>
              </b-form>
            </validation-observer>
          </div>
          </b-col>
        </b-row>
    </b-modal>
</template>
<script>
import axiosIns from '@/libs/axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BModal, BButton, VBModal, BAlert, BFormGroup, BFormInput, BRow, BCol, BForm, BFormSelect, BImg, BImgLazy, BCardText, BInputGroup, BInputGroupAppend, BCard, BEmbed  } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import ModalCategoria from '@/views/aplicaciones/medios/ModalCategoria.vue';
import vSelect from "vue-select";

export default {
    components: {
        BButton,
        BModal,
        BAlert,
        BFormGroup,
        BFormInput,
        ValidationProvider,
        ValidationObserver,
        BForm,
        BRow,
        BCol,
        BFormSelect,
        BImgLazy,
        BImg,
        BCardText,
        BInputGroup,
        BInputGroupAppend,
        BCard,
        vSelect,
        BEmbed
    },
    props:[
        'selected',
        'mediosCategoriaOpciones'
    ],
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    watch: {
      selected: function(val) {
        if (val) {
          this.categoria={title:this.selected.categoria.nombre,id: this.selected.categoria.id};
          this.titulo=this.selected.titulo;
          this.id=this.selected.id;
        }
      },
    },
    data () {
        return {
            id:null,
            required,
            submitted: false,
            errores:[],
            categoria:'',
            titulo:'',
        }
    },
    methods:{
      actualizarDatos() {
          this.$refs.simpleRules.validate().then(success => {
              if (success) {
                  if (!this.submitted) {
                      this.submitted = true;
                      this.actualizar();
                  }
              }
          })
      },
      actualizar(){
          axiosIns.put('/admin/medios/'+this.id,{
              'titulo':this.titulo,
              'mediosCategoria_id':this.categoria.id,
          })
          .then(res => {
              this.$nextTick(() => {
                  this.$refs['my-modal-ver'].toggle('#toggle-btn')
              })
              this.submitted = false;
              this.selected.titulo=this.titulo;
              this.errores=[];
          })
          .catch(err =>{
              this.$nextTick(() => {
                  this.$refs['my-modal-ver'].toggle('#toggle-btn')
              })
              this.submitted = false;
              this.errores=err.response.data.errors;
          });
      },
      confirmar_borrar(id) {
        this.$swal({
          title: "Advertencia",
          text: "Confirme que esta seguro de borrar este archivo. Esta acción no se puede deshacer.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "btn btn-danger",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.borrar_permanente(id);
          }
        });
      },
      borrar_permanente(id) {
        axiosIns.delete('/admin/medios/'+id)
        .then(res => {
            this.$bvToast.toast('Exito', {
              title: 'Se borro correctamente.',
              variant:'primary',
              solid: false,
            })
            this.$nextTick(() => {
                this.$refs['my-modal-ver'].toggle('#toggle-btn')
            })
            this.$emit('actualizarLista');
        }) 
        .catch(err =>{
            this.$nextTick(() => {
                this.$refs['my-modal-ver'].toggle('#toggle-btn')
            })
            console.log(err);
        });
      },
    }
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.boxsver1 img {
  object-fit: none;
}
.boxsv {
  height: calc(var(--vh, 1vh) * 100 - 14rem);
}
.boxsv img {
  width: 100%;
  height: 100%;
}
// .boxsv1 img {
//   object-fit: contain;
// }
@media (max-width: 700px) {
  .boxsver1 img {
    object-fit: contain;
  }
  .boxsv {
    height: calc(var(--vh, 1vh) * 100 - 30rem);
  }
}
#iframe{
  .embed-responsive {
      position: inherit;
      display: block;
      width: 100%;
      overflow: hidden;
  }
}
</style>