<template>
    <b-modal
        id="modal-playlist"
        ref="my-modal"
        :title="tituloModal"
        no-close-on-backdrop
        ok-title="Guardar"
        cancel-variant="outline-secondary"
        cancel-title="Cerrar"
        @cancel="cerrarModal"
        @close="cerrarModal"
        @ok.prevent="validar"
    >

     <validation-observer ref="simpleRules">
        <b-form>
            <b-row>
                <!-- titulo -->
                <b-col cols="12">
                    <b-form-group
                        label-for="Titulo"
                    >
                    <label >Titulo <span class="text-danger">(*)</span></label>
                    <validation-provider
                        #default="{ errors }"
                        name="Titulo"
                        rules="required|min:4"
                        >
                        <b-form-input
                            id="nombre"
                            v-model="title"
                            :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-for="error in errores.title" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>

                     <b-form-group
                        label="Descripcion"
                        label-for="Descripcion"
                    >
                        <b-form-textarea
                            id="textarea-default"
                            v-model="description"
                            rows="3"
                        />
                    </b-form-group>

                    <b-form-group
                        label-for=""
                        >
                        <label >Foto miniatura <span class="text-danger">(*)</span></label>
                        <validation-provider
                            #default="{ errors }"
                            name="foto miniatura"
                            rules="required"
                            >
                            <div class="rounded">
                                <div class="text-center">
                                <b-form-group label="" label-for="">
                                    <div class="box-imagen box-imagen1 border rounded">
                                    <b-img
                                        v-if="thumbnail"
                                        :src="thumbnail"
                                        center
                                        fluid
                                        thumbnail
                                        class="rounded"
                                        alt="Card image cap"
                                    />
                                    </div>
                                </b-form-group>
                                <b-button
                                    v-b-modal.modal-seleccionar-imagen
                                    variant="flat-dark"
                                    size="sm"
                                >
                                    <feather-icon icon="ImageIcon" class="mr-50" />
                                    <span class="align-middle">Elegir Imagen</span>
                                </b-button>
                                </div>
                            </div>
                            <b-form-input
                                class="hidden"
                                v-model="thumbnail"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small v-if="!thumbnail" class="text-danger">{{ errors[0] }}</small>
                            <small v-for="error in errores.thumbnail" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </validation-observer>
    <ImagenComponent @enviarImagen="recibirImagen"></ImagenComponent>
    </b-modal>
</template>
<script>
import axiosIns from '@/libs/axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BModal, BButton, VBModal, BAlert, BFormGroup, BFormInput, BRow, BCol, BForm, BFormSelect, BEmbed, BFormTextarea, BImg } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import ImagenComponent from '@/componentes/ImagenComponent.vue';

export default {
    components: {
        BButton,
        BModal,
        BAlert,
        BFormGroup,
        BFormInput,
        ValidationProvider,
        ValidationObserver,
        BForm,
        BRow,
        BCol,
        BFormSelect,
        BEmbed,
        BFormTextarea,
        BImg,
        ImagenComponent
    },
    props:[
        'accion','item'
    ],
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    watch: {
        accion: function () {
            this.errores=[]; 
            if (this.accion) {
                this.abrir(this.accion,this.item);
            }
        },
    },
    data () {
        return {
            id:null,
            title:'',
            description:'',
            thumbnail:'',
            required,
            submitted: false,
            errores:[],
            tituloModal:'',
        }
    },
    methods:{
        validar() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    if (!this.submitted) {
                        this.submitted = true;
                        if(this.tituloModal=='Agregar Playlist'){
                            this.registrar();
                        }else{
                            this.actualizar();
                        }
                    }
                }
            })
        },
        abrir(accion,data=[]){
            switch(accion){
                case "registrar":
                {
                    this.tituloModal='Agregar Playlist';
                    this.title='';
                    this.description='';
                    this.thumbnail='';
                    break;
                }
                case "actualizar":
                {
                    this.tituloModal='Editar Playlist';
                    this.id=data['id'];
                    this.title=data['title'];
                    this.description=data['description'];
                    this.thumbnail=data['thumbnail'];
                    break;	
                }
            }
        },
        cerrarModal(){
            this.$emit('cerrarComponente');
        },
        actualizarLista(){
            this.$emit('actualizarLista');
            this.submitted = false;
        },
        registrar(){
            axiosIns.post('/admin/playlist',{
                'title':this.title,
                'description':this.description,
                'thumbnail':this.thumbnail,
            })
            .then(res => {
                this.$nextTick(() => {
                    this.$refs['my-modal'].toggle('#toggle-btn')
                })
                this.actualizarLista();
                this.errores=[];
            })
            .catch(err =>{
                this.submitted = false;
                this.errores=err.response.data.errors;
            });
        },
        actualizar(){
            axiosIns.put('/admin/playlist/'+this.id,{
                'title':this.title,
                'description':this.description,
                'thumbnail':this.thumbnail,
            })
            .then(res => {
                this.$nextTick(() => {
                    this.$refs['my-modal'].toggle('#toggle-btn')
                })
                this.actualizarLista();
                this.errores=[];
            })
            .catch(err =>{
                this.submitted = false;
                this.errores=err.response.data.errors;
            });
        },
        recibirImagen(imagen){
            this.thumbnail=imagen.url;
        },
    }
}
</script>
<style lang="scss">
.box-imagen {
  height: 13rem;
}
.box-imagen img {
  width: 100%;
  height: 100%;
}
.box-imagen1 img {
  object-fit: contain;
}
</style>