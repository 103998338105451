<template>
    <div>
        <b-overlay :show="loading">
            <b-row>
                <b-col cols="12" md="6">
                    <b-card title="Playlist">
                        <b-row>
                            <b-col cols="12" md="6" class="mb-1">
                                <b-button
                                   v-b-modal.modal-playlist @click="accionPlayl='registrar'"
                                    variant="primary"
                                >
                                    <span class="text-nowrap">Nuevo PlayList</span>
                                </b-button>
                                
                            </b-col>
                            <b-col
                                cols="12"
                                md="6"
                            >
                                <div class="d-flex align-items-center justify-content-end">
                                    <b-button @click="actualizarLista()" variant="primary" class="btn-icon mr-1" >
                                        <feather-icon
                                            icon="RotateCcwIcon"
                                        />
                                        <span class="text-nowrap"> Actualizar</span>
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col cols="12" md="6">
                    <b-card title="API Youtube">
                        <b-row>
                            <b-col cols="12" md="6" class="mb-1">
                                <b-input-group>
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="YoutubeIcon" />
                                    </b-input-group-prepend>
                                    <b-form-input disabled placeholder="YouTube API" />
                                        <b-input-group-append>
                                        <b-button v-b-modal.modal-api-youtube variant="outline-primary"> 
                                            <feather-icon icon="Edit2Icon" />
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>
                            <b-col
                                cols="12"
                                md="6"
                            >
                                <div class="d-flex align-items-center justify-content-end">
                                    <b-button
                                        @click="validarSincronizar()"
                                        variant="primary"
                                    >
                                        <span class="text-nowrap">Sincronizar</span>
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </b-overlay>
        <div>
            <h6 class="text-muted">
                Lista de PlayList
            </h6>
            <b-row>
                <b-col
                    v-for="(item, index) in playListItems" :key="index"
                    sm="6"
                    md="3"
                    lg="3"
                    cols="12"
                >
                    <b-card
                        :img-src="item.thumbnail"
                        img-top
                        img-alt="card img"
                        :header="item.title"
                        class=""
                    >
                        <b-card-text>
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h6 class="text-muted font-weight-bolder text-truncate">
                                            {{item.play_list_item_count}} videos
                                        </h6>
                                    </div>
                                    <div>
                                        <h6 class="text-muted font-weight-bolder text-truncate">
                                            {{item.published_at}}
                                        </h6>
                                    </div>
                                </div>
                            <div class="text-nowrap">
                                <b-button
                                    v-if="item.id!=1 && item.opcion_id!=1"
                                    v-b-modal.modal-playlist @click="abriComponente(item)" 
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                    variant="outline-primary"
                                    v-b-tooltip.hover.bottom="'Editar'"
                                    class="btn-icon mr-1"
                                    size="sm"
                                >
                                    <feather-icon icon="EditIcon" />
                                </b-button>

                                <b-button
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                    variant="outline-dark"
                                    :to="{ name: 'video-playlist', params: { id: item.url } }"
                                    v-b-tooltip.hover.bottom="'Ver videos'"
                                    class="btn-icon mr-1"
                                    size="sm"
                                >
                                    <feather-icon icon="YoutubeIcon" />
                                </b-button>

                                <b-button
                                    v-if="item.id!=1 && item.opcion_id!=1"
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                    variant="outline-danger"
                                    @click="confirmar_eliminar(item.id)"
                                    v-b-tooltip.hover.bottom="'Eliminar'"
                                    class="btn-icon"
                                    size="sm"
                                >
                                    <feather-icon icon="TrashIcon" />
                                </b-button>
                            </div>
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
        </div>
        <ApiYoutube ref="editarApi" />
        <PlayListNuevoEditar 
        :accion="accionPlayl" 
        :item="ItemPlayl" 
        @cerrarComponente="cerrarComponente" 
        @actualizarLista="actualizarLista" >
        </PlayListNuevoEditar>
    </div>
</template>
<script>
import axiosIns from '@/libs/axios'
import {BCard, BCardText, BButton, BRow, BCol, BCardBody, BCardTitle, BCardSubTitle, BLink, BFormGroup, BFormInput, BForm, BFormTextarea, BImg, BDropdown, BDropdownItem, BListGroup, BListGroupItem, VBTooltip, BMedia, BMediaAside, BMediaBody, BTable, BInputGroup, BPagination, BOverlay, BInputGroupPrepend, BInputGroupAppend} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import ApiYoutube from '@/views/videos/youtube/ApiYoutube.vue';
import PlayListNuevoEditar from '@/views/videos/PlayListNuevoEditar.vue';
import axios from 'axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { computed } from '@vue/composition-api';
import store from '@/store'
export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BImg, 
    BDropdown, BDropdownItem,
    BListGroup, BListGroupItem,
    ApiYoutube,
    BMedia, BMediaAside, BMediaBody,
    BTable,
    BInputGroup,
    BPagination,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BInputGroupPrepend, BInputGroupAppend,PlayListNuevoEditar
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data () {
      return {
        errores:[],
        items: [],
        playlists:[],
        itemPlaylist:[],
        key:'',
        channelId:'',
        base_url:'https://www.googleapis.com/youtube/v3/search',
        base_url_playlistItems:'https://www.googleapis.com/youtube/v3/playlistItems',
        submitted: false,
        pageToken:'',
        items_aux: [],
        loading: false,
        channel:'',
        id:'',
        status:false,
        itemPlaylistGeneral:[],
        ItemPlayl:[],
        accionPlayl:'',
        playListItems:[],
      }
  },
  computed:{

  },
  created(){    
    this.listarVideos();
  },
  watch: {
    channelId: function(val) {

    },
    key: function(val) {

    },
    status: function(val) {
        if (val) {
            this.sincronizarVideos();
        }
    },
  },
  methods: {
    validarSincronizar() {
        if (!this.submitted) {
            this.submitted = true;
            this.loading = true;
            this.channelId=this.$refs.editarApi.channelId;
            this.key=this.$refs.editarApi.key
            this.channel=this.$refs.editarApi.channel;
            this.id=this.$refs.editarApi.id
            this.sincronizar();
        }
    },
    async sincronizar(){
        let params={
            key: this.key,
            channelId: this.channelId,
            part: 'snippet,id',
            order: 'date',
            //type: 'playlist',
            maxResults: 50,
            pageToken: this.pageToken
        };
        axios.get(this.base_url,{params})
        .then(res => {
            let resItem = res.data.items.map(g => ({
                id:g.id,
                kind:g.id.kind,
                videoId:g.id.videoId,
                playlistId:g.id.playlistId,
                channelId:g.id.channelId,
                title:g.snippet.title,
                description: g.snippet.description,
                thumbnail:g.snippet.thumbnails.medium.url,
                publishedAt:g.snippet.publishedAt
            }));
            this.items_aux = this.items_aux.concat(resItem);
            if (res.data.nextPageToken) {
                this.pageToken=res.data.nextPageToken;
                this.sincronizar();
            }else{
                this.playlists=this.items_aux.filter(function (el){return el.kind === "youtube#playlist";});
                this.items=this.items_aux.filter(function (el){return el.kind === "youtube#video";});
                if (this.playlists.length) {
                    this.sincronizarPlaylist();
                }else{
                    this.sincronizarVideos();
                }
            }
        })
        .catch(err =>{
            //this.items=[];
            this.status=false;
            this.itemPlaylist=[];
            this.itemPlaylistGeneral=[];
            this.items_aux=[];
            this.submitted = false;
            this.loading = false;
            this.$bvToast.toast('Error', {
                title: 'la Cuota ha exedido el numero de sincronizaciones',
                variant:'danger',
                solid: false,
            })
        });
    },
    async sincronizarPlaylist(){
        for (let index = 0; index < this.playlists.length; index++) {
            let params={
                key: this.key,
                playlistId: this.playlists[index].playlistId,
                part: 'snippet,id',
                order: 'date',
                maxResults: 50,
            };
            axios.get(this.base_url_playlistItems,{params})
            .then(res => {
                let resItemPlaylist=res.data.items.filter(function (el){return el.snippet.title != "Private video";});
                let resItem = resItemPlaylist.map(g => ({
                    videoId:g.snippet.resourceId.videoId,
                    playlistId:g.snippet.playlistId,
                    title:g.snippet.title,
                    description: g.snippet.description,
                    thumbnail:g.snippet.thumbnails.medium.url,
                    publishedAt:g.snippet.publishedAt
                }));
                this.itemPlaylist = this.itemPlaylist.concat(resItem);
                if((index+1)==this.playlists.length){
                    setTimeout( ()=> {
                        this.$nextTick(() => {
                            this.status=true
                        })
                    }, 1000);
                }
            })
            .catch(err =>{
                this.status=false;
                this.items_aux=[];
                this.itemPlaylist=[];
                this.itemPlaylistGeneral=[];
                this.submitted = false;
                this.loading = false;
                this.$bvToast.toast('Error', {
                    title: 'la Cuota ha exedido el numero de sincronizaciones',
                    variant:'danger',
                    solid: false,
                })
            });
        }
    },
    sincronizarVideos(){
        let yFilter = this.itemPlaylist.map(itemY => { return itemY.videoId; });
        let filteredX = this.items.filter(itemX => !yFilter.includes(itemX.videoId));
        this.itemPlaylistGeneral = filteredX.map(g => ({
            videoId:g.videoId,
            playlistId:1,
            title:g.title,
            description: g.description,
            thumbnail:g.thumbnail,
            publishedAt:g.publishedAt
        }));
        const valor = `{"attributes":{"key":"${this.key}","channelId":"${this.channelId}","channel":"${this.channel}"}}`;
        axiosIns.post('/admin/videos-sincronizar',{
            'opcion_id':this.id,
            'valor':valor,
            'data':this.items,
            'playlists':this.playlists,
            'itemPlaylist':this.itemPlaylist,
            'itemPlaylistGeneral':this.itemPlaylistGeneral,
        })
        .then(res => {
            this.$bvToast.toast('Exito', {
                title: 'Sincronizado con exito',
                variant:'primary',
                solid: false,
            })
            store.dispatch('auth/attempt',localStorage.getItem('token')).then(()=>{})
            this.status=false;
            this.items_aux=[];
            this.itemPlaylist=[];
            this.itemPlaylistGeneral=[];
            this.pageToken='';
            this.submitted = false;
            //this.loading = false;
            this.listarVideos();
        })
        .catch(err =>{
            this.items_aux=[];
            this.$bvToast.toast('Error', {
                title: 'Algo salio mal',
                variant:'danger',
                solid: false,
            })
            this.items_aux=[];
            this.itemPlaylist=[];
            this.itemPlaylistGeneral=[];
            this.pageToken='';
            this.status=false;
            this.submitted = false;
            this.loading = false;
        });
    },
    async listarVideos() { 
        var url= '/admin/videos/playlist';
        axiosIns.get(url)
        .then(res => {
            this.playListItems = res.data
            this.loading=false;  
        })
        .catch(err =>{
            console.log(err);
        });
    },

    confirmar_eliminar(id) {
        this.$swal({
        title: "Advertencia",
        text: "¿Estás seguro de que deseas remover la playlist y su lista de videos?.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
            confirmButton: "btn btn-danger",
            cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        }).then((result) => {
        if (result.value) {
            this.eliminar(id);
        }
        });
    },
    eliminar(id) {
        axiosIns.delete('/admin/playlist/'+id)
        .then(res => {
            this.$bvToast.toast('Exito', {
            title: 'Se removio correctamente',
            variant:'primary',
            solid: false,
            })
            this.listarVideos();
        }) 
        .catch(err =>{
            console.log(err);
        });
    },

    //componente Playlist
    actualizarLista(){
        this.loading=true;
        this.accionPlayl='';
        this.ItemPlayl=[];
        this.listarVideos();
    },
    cerrarComponente(){
        this.accionPlayl='';
        this.ItemPlayl=[];
    },
    abriComponente(item){
      this.accionPlayl="actualizar";
      this.ItemPlayl=item;
    },
  },
}
</script>
<style lang="scss">

</style>