<template>
    <b-modal
        id="modal-medios-categoria"
        ref="my-modal"
        :title="titulo"
        no-close-on-backdrop
        ok-title="Guardar"
        cancel-variant="outline-secondary"
        cancel-title="Cerrar"
        @cancel="cerrarModal"
        @close="cerrarModal"
        @ok.prevent="validar"
    >
     <validation-observer ref="simpleRules">
        <b-form>
            <b-row>
                <!-- nombre -->
                <b-col cols="12">
                    <b-form-group
                        label-for="Nombre"
                    >
                        <label >Nombre <span class="text-danger">(*)</span></label>
                        <validation-provider
                        #default="{ errors }"
                        name="Nombre"
                        rules="required|min:4"
                        >
                        <b-form-input
                            id="nombre"
                            v-model="nombre"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Categoria"
                        />
                        <!-- :state="(errors.length > 0 || errores.nombre) ? false:null" -->
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-for="error in errores.nombre" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </validation-observer>

    </b-modal>
</template>
<script>
import axiosIns from '@/libs/axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BModal, BButton, VBModal, BAlert, BFormGroup, BFormInput, BRow, BCol, BForm, BFormSelect } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'

export default {
    components: {
        BButton,
        BModal,
        BAlert,
        BFormGroup,
        BFormInput,
        ValidationProvider,
        ValidationObserver,
        BForm,
        BRow,
        BCol,
        BFormSelect 
    },
    props:[
        'accion','item'
    ],
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    watch: {
        accion: function () {
            this.errores=[]; 
            if (this.accion) {
                this.abrir(this.accion,this.item);
            }
        },
        nombre: function () { 
            this.errores.nombre=[];
        },
        descripcion: function () { 
            this.errores.descripcion=[];
        },
    },
    data () {
        return {
            id:null,
            nombre:'',
            titulo:'',
            required,
            submitted: false,
            errores:[],
        }
    },
    methods:{
        validar() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    if (!this.submitted) {
                        this.submitted = true;
                        if(this.titulo=='Nueva Categoria'){
                            this.registrar();
                        }else{
                            this.actualizar();
                        }
                    }
                }
            })
        },
        abrir(accion,data=[]){
            switch(accion){
                case "registrar":
                {
                    this.titulo='Nueva Categoria';
                    this.nombre='';
                    break;
                }
                case "actualizar":
                {
                    this.titulo='Editar Categoria';
                    this.id=data['value'];
                    this.nombre=data['title'];
                    break;	
                }
            }
        },
        cerrarModal(){
            this.$emit('cerrarComponente');
        },
        actualizarLista(item){
            this.$emit('actualizarLista',item);
            this.submitted = false;
        },
        registrar(){
            axiosIns.post('/admin/medios-categorias',{
                'nombre':this.nombre,
            })
            .then(res => {
                this.$nextTick(() => {
                    this.$refs['my-modal'].toggle('#toggle-btn')
                })
                this.actualizarLista(res.data);
                this.errores=[];
            })
            .catch(err =>{
                this.submitted = false;
                this.errores=err.response.data.errors;
            });
        },
        actualizar(){
            axiosIns.put('/admin/medios-categorias/'+this.id,{
                'nombre':this.nombre,
            })
            .then(res => {
                this.$nextTick(() => {
                    this.$refs['my-modal'].toggle('#toggle-btn')
                })
                this.actualizarLista(res.data);
                this.errores=[];
            })
            .catch(err =>{
                this.submitted = false;
                this.errores=err.response.data.errors;
            });
        }
    }
}
</script>